import http from '../http';

const userApi = {
	auth(data) {
		return http.post('/user/auth', data);
	},
	forgotPassword(data) {
		return http.post('/user/forgot-password', data);
	},
	resetPassword(data) {
		return http.post('/user/reset-password', data);
	},
}

const applicationApi = {
	submit(data) {
		return http.post('/application-submit', data);
	}
}

const bookingApi = {
	bookExperience(data) {
		return http.post('/book-experience', data);
	},
	getVoucher(voucher) {
		return http.get('/get-voucher/' + voucher);
	}
}

export {
	userApi,
	applicationApi,
	bookingApi
}