import '@fancyapps/fancybox/dist/jquery.fancybox.css';
import '../sass/sass.scss';
import './functions.js';
import moment from 'moment/moment';
import _ from 'lodash';
import Cleave from 'vue-cleave-component';

import Vue from 'vue';
Vue.use(Cleave);

Vue.prototype.$app = app;
Vue.prototype.$moment = moment;
Vue.prototype.$_ = _;
Vue.config.productionTip = false;

// main
Vue.component('login', require('./components/login').default);
Vue.component('forgot-password', require('./components/forgot-password').default);
Vue.component('reset-password', require('./components/reset-password').default);
Vue.component('application-form', require('./components/application-form').default);
Vue.component('booking-form', require('./components/booking-form').default);

new Vue({
	el: '#app',
});