import 'owl.carousel';
import helper from './helpers';
import '@fancyapps/fancybox';
import 'zurb-twentytwenty/js/jquery.event.move.js';
import 'zurb-twentytwenty/js/jquery.twentytwenty.js';
import {camelCase, upperFirst} from 'lodash';
// mobile nav
function activateNavMenu() {
	$('#nav-menu').on('click', function() {
		var self = $(this), 
			app = $('#app'),
			activeClass = 'menu-active';
		app.toggleClass(activeClass);
		self.toggleClass('fa-times');
		if(app.hasClass(activeClass)) {
			$('#nav a').on('click', function() {
				app.removeClass(activeClass);
				self.removeClass('fa-times');
			});
		}
	});
}
// scroll header
function ScrollHeader() {
	var scrollTarget = $('#header'),
		scrollClass = 'collapse';
	if( helper.isElementScrolledTo(scrollTarget[0].id) ) {
		scrollTarget.addClass(scrollClass);
	} else {
		scrollTarget.removeClass(scrollClass);
	}
}
// scroll foot ctas
function ScrollFooterCtas() {
	var scrollTarget = $('#foot-ctas'),
		scrollClass = 'collapse',
		body = $('body');
	if(!body.hasClass('page-template-default') && !body.hasClass('error404')) {
		var detect = function() {
			if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - scrollTarget.outerHeight())) {
				scrollTarget.addClass(scrollClass);
			} else {
				scrollTarget.removeClass(scrollClass);
			}
		}
		detect();
		$(window).on('scroll', detect);
	}
}
// ui toggle
function enableUIToggle() {
	var toggle = $('.ui-toggle');
	toggle.each(function() {
		var load = $(this).data('toggle');
		if(load == 'show') {
			$(this).find('.ui-toggle-content').css("display", "block");
		}
	});
	$('.ui-toggle-title').on('click', function() {
		$(this).next('.ui-toggle-content').toggle();
	});
}
// checkbox access
function doCheckboxAccess() {
	$('.ui-checkbox input').on('keypress', function(e) {
		e.preventDefault();
		if(e.keyCode === 13) {
			$(this).prop('checked', !$(this).prop('checked'));
		}
	});
}
// reposition owl nav buttons
function repositionOwlNav() {
	var owl = $('.owl-carousel.owl-loaded');
	var height = owl.parent('.slider').outerHeight() - (owl.find('.owl-nav button').outerHeight() / 2);
	owl.find('.owl-nav button').css("top", height / 2);
}
// init owl slider
function initOwlSlider() {
	var owl = $('.slider .owl-carousel');
	owl.owlCarousel({
		loop: true,
		nav: true,
		dots: false,
		autoHeight: true,
		navText: [
			"<span class='fa fa-chevron-left'></span>",
			"<span class='fa fa-chevron-right'></span>"
		],
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 2
			},
			800: {
				items: 3
			},
			1000: {
				items: parseInt(owl.data('desktop-items')) || 4
			}
		}
	});
	setTimeout(function() {
		repositionOwlNav();
	}, 1000);
}
// back to top
function backToTop() {
	var el = $('#backToTop');
	el.on('click', function() {
		$("html, body").animate({
			scrollTop: 0
		}, 500);
	});
	$(window).on('scroll', function() {
		if ($(this).scrollTop() > 300) {
			el.fadeIn();
		} else {
			el.fadeOut();
		}
	});
}
// smooth scroll
function smoothScroll() {
	$('a[href^="#"]').on('click', function(event) {
		var target = $(this.getAttribute('href'));
		if( target.length ) {
			event.preventDefault();
			$('html, body').stop().animate({
				scrollTop: target.offset().top - ($('#header.collapse').outerHeight() || 0)
			}, 1000);
		}
	});
}
// triggerModal
function triggerModal(targetname, previewModal = 0, allowOutsideClick = 0, callback = null) {
	var $class = 'active',
		$targetname = targetname,
		$target = $('#' + targetname),
		$bodyClass = 'modal-active modal-' + targetname,
		$modal = $('.triggerModal' + _.upperFirst(_.camelCase(targetname)));
	function showModal() {
		$target.addClass($class);
		$('body').addClass($bodyClass);
	}
	function close() {
		$target.removeClass($class);
		$('body').removeClass($bodyClass);
		return callback ? callback() : undefined;
	}
	if(previewModal) {
		showModal();
	} else {
		$modal.on('click', function() {
			showModal();
		});
	}
	if(allowOutsideClick) {
		$target.on('click', function(e) {
			if(e.target.id === targetname) {
				close();
			}
		});
	}
	$target.find('.modal-close').on('click', function() {
		close();
	});
}
// custom html video
function customHtmlVideo() {
	$('video.html-player').each(function() {
		var vid = $(this);
		var play = false;
		vid.wrap('<div class="custom-html-player"></div>');
		var wrap = vid.parent('.custom-html-player');
		wrap.prepend('<div class="overlay"></div>');
		wrap.append('<div class="icon"><span class="fa fa-play"></span></div>');

		wrap.find('.icon, .overlay').on('click', function() {
			if(wrap.hasClass('playing')) {
				play = false;
			} else if(wrap.hasClass('paused')) {
				play = true;
			} else {
				play = true;
			}
			checkPlayStatus();
		});

		function checkPlayStatus() {
			if(play == true) {
				vid[0].play();
				vid.css('object-fit', 'contain');
				wrap.removeClass('paused').addClass('playing');
			} else {
				vid[0].pause();
				wrap.removeClass('playing').addClass('paused');
			}
		}
	});
}
jQuery(document).ready(function($) {
	// scroll header
	ScrollHeader();
	// ScrollFooterCtas
	ScrollFooterCtas();
	// smooth scroll
	smoothScroll();
	// init owl slider
	initOwlSlider();
	// mobile nav
	activateNavMenu();
	// tabs
	helper.enableTabs();
	// ui select
	helper.enableUISelect();
	// ui toggle
	enableUIToggle();
	// checkbox access
	doCheckboxAccess();
	// customHtmlVideo
	customHtmlVideo();
	// trigger modal
	function onOrthoModalClose() {
		helper.setCookie('ortho-modal-close', true, 14);
	}
	$('#ortho-popup .ui-btn').on('click', function() {
		onOrthoModalClose();
	});
	if(!helper.getCookie('ortho-modal-close') && $('body').hasClass('home')) {
		triggerModal('ortho-popup', 1, 0, onOrthoModalClose);
	}
	// on scroll
	$(window).on('scroll', function() {
		// if scroll meets end of page, stop scrolling
		if($(window).scrollTop() + $(window).height() != $(document).height()) {
			ScrollHeader();
		}
	});
	// on resize
	$(window).on('resize', function() {
		setTimeout(function() {
			repositionOwlNav();
		}, 1000);
	});
	// fancybox
	$('[data-fancybox]').fancybox();
});