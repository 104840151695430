<template>
	<div id="application-form">
		<form @submit.prevent="submitApplication" novalidate>
			<div class="col-row">
				<div class="col s12 m4 l4">
					<div class="form-block">
						<label class="form-label" data-field="name">
							<div class="form-field input">
								<input v-model="model.name" type="text" class="field" placeholder="Name" required>
							</div>
						</label>
					</div>
				</div>
				<div class="col s12 m4 l4">
					<div class="form-block">
						<label class="form-label" data-field="practice_name">
							<div class="form-field input">
								<input v-model="model.practice_name" type="text" class="field" placeholder="Practice Name" required>
							</div>
						</label>
					</div>
				</div>
				<div class="col s12 m4 l4">
					<div class="form-block">
						<label class="form-label" data-field="practice_postcode">
							<div class="form-field input">
								<input v-model="model.practice_postcode" type="text" class="field" placeholder="Practice Postcode" required>
							</div>
						</label>
					</div>
				</div>
			</div>
			<div class="col-row">
				<div class="col s12 m4 l4">
					<div class="form-block">
						<label class="form-label" data-field="email">
							<div class="form-field input">
								<input v-model="model.email" type="email" class="field" placeholder="Email" maxlength="64" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
							</div>
						</label>
					</div>
				</div>
				<div class="col s12 m4 l4">
					<div class="form-block">
						<label class="form-label" data-field="mobile_number">
							<div class="form-field input">
								<input v-model="model.mobile_number" type="tel" class="field" placeholder="Mobile Number" required>
							</div>
						</label>
					</div>
				</div>
				<div class="col s12 m4 l4" v-if="event != 'nordics-apply'">
					<div class="form-block">
						<label class="form-label" data-field="gdc_number">
							<div class="form-field input">
								<input v-model="model.gdc_number" type="tel" min="0" class="field" placeholder="GDC Number" required>
							</div>
						</label>
					</div>
				</div>
			</div>
			<div class="col-row">
				<div class="col s12 m12 l12" v-if="event != 'orthos'">
					<div class="form-block">
						<fieldset class="form-label" data-field="invisalign_provider">
							<span class="label">Invisalign Provider</span>
							<div class="ui-radio" v-for="type in list.invisalign_provider_types">
								<label>
									<input type="radio" v-model="model.invisalign_provider" :value="type" />
									<span class="radio"></span>
									<span v-text="type"></span>
								</label>
							</div>
						</fieldset>
					</div>
				</div>
				<div class="col s12 m12 l12">
					<div class="form-block">
						<fieldset class="form-label" data-field="practice_role">
							<span class="label">Practice Role</span>
							<div class="ui-radio" v-for="type in list.practice_roles">
								<label>
									<input type="radio" v-model="model.practice_role" :value="type" />
									<span class="radio"></span>
									<span v-text="type"></span>
								</label>
							</div>
						</fieldset>
					</div>
				</div>
				<div class="col s12 m12 l12">
					<div class="form-block">
						<fieldset class="form-label" data-field="corporate">
							<span class="label">Are you part of a corporate?</span>
							<div class="ui-radio" v-for="type in list.corporate">
								<label>
									<input type="radio" v-model="model.corporate" :value="type" />
									<span class="radio"></span>
									<span v-text="type"></span>
								</label>
							</div>
						</fieldset>
					</div>
				</div>
				<div class="col s12 m12 l12">
					<div class="form-block">
						<div class="form-block">
							<label class="form-label" data-field="invisalign_case_vol">
								<div class="form-field input">
									<input v-model="model.invisalign_case_vol" type="text" class="field" placeholder="Invisalign case volume in the last 12 months" required>
								</div>
							</label>
						</div>
					</div>
				</div>
				<div class="col s12 m12 l12">
					<div class="form-block">
						<div class="form-block">
							<label class="form-label" data-field="why_be_part_of_experience">
								<div class="form-field textarea">
									<textarea v-model="model.why_be_part_of_experience" class="field" placeholder="Tell us why you want to be part of the Mastering your Invisalign Business course." rows="5" cols="5" required></textarea>
								</div>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="form-block">
				<div class="form-label" data-field="consent">
					<div class="ui-checkbox">
						<label>
							<input type="checkbox" v-model="model.consent"> 
							<span class="checkbox"></span>
							<span>I agree to Mastering your Invisalign Business and the MiSmile group using my personal data to provide me with information about the Mastering your Invisalign Business course.</span>
						</label>
					</div>
				</div>
				<div class="form-label" data-field="consent_marketing">
					<div class="ui-checkbox">
						<label>
							<input type="checkbox" v-model="model.consent_marketing"> 
							<span class="checkbox"></span>
							<span>I agree to Mastering your Invisalign Business and the MiSmile group using my personal data to keep me informed about marketing offers and initiatives that may be of interest.</span>
						</label>
					</div>
				</div>
			</div>
			<div class="form-block referral-code">
				<label class="form-label">
					<div class="form-field input">
						<input v-model="model.referral_code" type="text" class="field" placeholder="Referrer Name">
					</div>
				</label>
			</div>
			<div class="submit">
				<button type="submit" class="ui-btn ui-btn-rounded" v-text="sending ? 'Submitting...' : 'Apply Now'" :disabled="sending"></button>
			</div>
		</form>
	</div>
</template>
<script>
	import helper from '../../helpers';
	import {applicationApi} from '../../api';
	export default {
		props: {
			event: {
				required: false
			},
		},
		data () {
			return {
				sending: false,
				list: {
					invisalign_provider_types: ['Full', 'Go'],
					practice_roles: ['Principal', 'Owner', 'Associate'],
					corporate: ['Yes', 'No']
				},
				model: {}
			}
		},
		methods: {
			processApplication() {
				let self = this;
				self.sending = true;
				$('span.error').remove();
				applicationApi.submit(self.model).then(response => {
					self.sending = false;
					let res = response.data;
					if(!res.error) {
						window.location.href = self.$app.site_url + '/thank-you-for-your-application/';
					}
				}, (error) => {
					var errors = error.response.data;
					if(errors.error) {
						if(errors.code === 'validation_errors') {
							helper.appendErrors(errors.errors);
							self.sending = false;
						}
					} else {
						alert("There was a problem, please try again later");
					}
					console.log(errors);
				});
			},
			submitApplication() {
				let self = this;
				self.processApplication();
			},
			initModel() {
				let self = this;
				self.model = {
					name: "",
					email: "",
					mobile_number: "",
					practice_name: "",
					practice_postcode: "",
					gdc_number: "",
					invisalign_provider: "",
					practice_role: "",
					corporate: "",
					invisalign_case_vol: "",
					why_be_part_of_experience: "",
					consent: false,
					consent_marketing: false,
					referral_code: "",
					utm_source: helper.$_GET('utm_source') || "",
					utm_medium: helper.$_GET('utm_medium') || "",
					utm_campaign: helper.$_GET('utm_campaign') || "",
					utm_term: helper.$_GET('utm_term') || "",
					utm_content: helper.$_GET('utm_content') || "",
					event: self.event,
				}
			},
			initMounted() {
				let self = this;
				self.initModel();
			}
		},
		mounted() {
			let self = this;
			self.initMounted();
		}
	}
</script>